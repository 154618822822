import { useRecords } from "~/stores/records";
import type { ComputedRef, Ref } from "vue";
import { computed } from "vue";
import type { CollectionNames } from "~/services/store";
import { toReference } from "~/services/store";

export default function <T>(collectionName: Ref<CollectionNames>, id: Ref<string>): ComputedRef<T | null> {
	const records = useRecords();

	return computed<T | null>(() => {
		return (records.get(toReference(collectionName.value, id.value)) as T) ?? null;
	});
}
