import { useRecords } from "@/stores/records";
import { useOperations } from "@/stores/operations";
import type { Ref } from "vue";
import { StoreProviderTypes, StoreOperationTypes, update, pushRecords, ClientReloadPolicy, toReference } from "~/services/store";

import type { StoreOperation, StoreFindOperation, StoreRecord, StoreQueryParams, StoreRecordInternals, StoreData, CollectionNames } from "~/services/store";

import { v4 as uuidv4 } from "uuid";
import { get, register } from "~/stores/tasks";
import cacheHandler from "./cacheHandler";

export default function (
	collectionName: Ref<CollectionNames>,
	id: Ref<string>,
	params?: StoreQueryParams
): { operation: ComputedRef<StoreFindOperation | undefined>; task: ComputedRef<Promise<boolean> | undefined> } {
	const nuxtApp = useNuxtApp();
	const restapi = nuxtApp.$restapi;
	const logger = nuxtApp.$logger;
	const records = useRecords();
	const operations = useOperations();

	//process.server = true;

	function listOperations(ssr: boolean) {
		return (
			operations.list
				.sort((a: StoreOperation, b: StoreOperation) => {
					return a.timestamp - b.timestamp;
				})
				.filter((item: StoreOperation) => item.type === StoreOperationTypes.find && item.ssr === ssr) as StoreFindOperation[]
		).filter((item) => item.reference === toReference(collectionName.value, id.value));
	}

	// create computed reference once
	const operation = computed<StoreFindOperation | undefined>(() => {
		return listOperations(process.server ? true : false).filter((item) => item.processing)[0];
	});

	// create computed task value
	let task = computed<Promise<boolean> | undefined>(() => {
		if (operation.value) {
			return get(operation.value.id);
		} else {
			return undefined;
		}
	});

	watch(
		[collectionName, id],
		() => {
			let ssrOperations = listOperations(true);
			let clientOperations = listOperations(false);
			let record = records.get(toReference(collectionName.value, id.value));

			if (cacheHandler(ssrOperations, clientOperations, params)) return;
			// record may have been already loaded via a list query operation
			if (params?.reloadOnClient === ClientReloadPolicy.never && record) return;

			// if there is an ongoing operation already
			if (operation.value) {
				return;
			}

			const timestamp = Date.now();

			const operationId = operations.push({
				type: StoreOperationTypes.find,
				id: uuidv4(),
				reference: toReference(collectionName.value, id.value),
				processing: true,
				awaiters: [],
				timestamp: timestamp,
				provider: process.server ? params?.provider || StoreProviderTypes.restapi : StoreProviderTypes.restapi,
				ssr: process.server ? true : false,
				count: ssrOperations.length + clientOperations.length,
			} as StoreFindOperation);

			if (record) {
				update(records, toReference(collectionName.value, id.value), { internals: { reloading: true } } as StoreRecord);
			}

			let task = restapi
				.getDocument(toReference(collectionName.value, id.value))
				.then((document) => {
					pushRecords(records, [document], collectionName.value, { saved: true, reloading: false } as StoreRecordInternals);

					return true;
				})
				.catch((error) => {
					logger.error(error);
					operations.update(operationId, { error } as StoreFindOperation);
					throw error;
				})
				.finally(() => {
					operations.update(operationId, { processing: false, elapsed_time: Date.now() - timestamp } as StoreFindOperation);
				});

			register(operationId, task);
		},
		{ immediate: true }
	);

	return { operation, task };
}
