import type { CollectionNames, StoreOperation } from "~/services/store";
import type { ComputedRef, Ref } from "vue";
import type { StoreQueryParams, StoreFindOperation } from "~~/services/store";
import createFindOperation from "./operations/createFindOperation";
import findRecord from "./records/findRecord";

export default function <T>(
	collectionName: Ref<CollectionNames>,
	id: Ref<string>,
	params: StoreQueryParams = {}
): { record: ComputedRef<T | null>; operation: ComputedRef<StoreFindOperation | undefined>; task: ComputedRef<Promise<boolean> | undefined>; loading: ComputedRef<boolean> } {
	// Create once, this is fully reactive itself
	const record = findRecord<T>(collectionName, id);

	// Create once, this is fully reactive itself
	const { operation, task } = createFindOperation(collectionName, id, params);

	const loading = computed(() => {
		return record.value ? false : true;

		/* const filtered = operations.list.filter((item: StoreOperation) => item.type === StoreOperationTypes.query) as StoreQueryOperation[];

		const completed = filtered.filter((item) => item.collectionName === collectionName.value && item.processing === false && JSON.stringify(item.filters) === JSON.stringify(filters?.value));

		return completed.length === 0; */
	});

	return { record, operation, task, loading };
}
